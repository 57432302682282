<template>
  <div>
    <div v-if="url && type.prop === 'links'">
      <v-btn block color="primary" small @click="clickButton()">
        <v-icon left>{{ type.icon }}</v-icon>
        {{ titleCompute }}
      </v-btn>
    </div>

    <div
      v-if="
        (url && (type.prop === 'streetviews' || type.prop === 'videos')) ||
          type.prop === 'gallery'
      "
    >
      <v-btn block color="primary" small @click="clickButton()">
        <v-icon left>{{ type.icon }}</v-icon>
        {{ titleCompute }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../main";

export default {
  name: "mediaButton",

  components: {},

  data: () => ({}),
  props: {
    width: Object,
    type: Object,
    title: String,
    markertitle: String,
    url: String,
    gallery: Array
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
    clickButton() {
      if (
        this.type.prop === "links" ||
        (this.type.prop === "videos" && !this.url?.includes("youtube"))
      ) {
        this.openLink(this.url);
      } else {
        //do nothing;
        eventBus.$emit("loadMediaDialog", {
          type: this.type,
          title: this.titleCompute,
          markertitle: this.markertitle,
          url: this.url,
          gallery: this.gallery
        });
      }
    }
  },
  computed: {
    titleCompute() {
      if (this.title) {
        return this.title;
      } else {
        return this.type.single;
      }
    }
  }
};
</script>

<style>
div.mediaButtonsWrapper {
  width: 300px;
}
</style>
