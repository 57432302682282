<template>
  <v-card class="mb-2 report-row" v-if="campsite">
    <v-card-title>
      <v-icon>mdi-tent</v-icon>Campground: {{ campsite.title }} ({{
        campsite.id
      }})
    </v-card-title>

    <v-card-text>
      <PopupDetails :properties="campsite"></PopupDetails>
    </v-card-text>

    <v-card-text class="text--primary">
      {{ campsite.Comment }}
    </v-card-text>
  </v-card>
</template>

<script>
import PopupDetails from "./../infoblocks/PopupDetails";

export default {
  name: "tripCampSite",
  components: { PopupDetails },

  props: {
    campsite: Object
  },

  computed: {},
  mounted() {}
};
</script>
