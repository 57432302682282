var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto",attrs:{"max-width":"450"}},[_c('v-toolbar',{attrs:{"color":"cyan","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("List")])],1),_c('div',{staticClass:"campsiteListWrapper"},[(_vm.filteredStats && _vm.filteredStats.fromTitle)?_c('journeyStats',{staticClass:"pa-2",attrs:{"iteminfo":_vm.filteredStats}}):_vm._e(),_c('br'),_c('div',[_c('tripReport')],1),_c('br'),_c('br'),_c('div',[(_vm.elevationData && _vm.elevationData.campsiteDistElevationArray)?_c('elevationGraph'):_vm._e()],1),_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.filteredCampsitesArray),function(item,index){return [_c('v-list-item',{key:item.id + ' ' + index},[_c('v-list-item-avatar',{staticStyle:{"margin":"16px 8px 8px -8px"},attrs:{"size":"24","color":_vm.appSettings.markerColors[item.pointType]}},[(false)?_c('font',[_vm._v(_vm._s(item.journeyDay))]):_vm._e(),_vm._v(" "+_vm._s(item.id)+" ")],1),_c('v-list-item-content',[_c('a',{on:{"click":function($event){return _vm.openPopup(item.id)}}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                  ((item.Campsite) + " - (" + (item.id) + ") " + (item.pointType != 'finish' &&
                    item.pointType != 'start' &&
                    item.legSkip
                      ? '-skipped'
                      : ''))
                )}})],1),(item.pointType != 'finish' && item.pointType != 'start')?_c('v-btn',{staticClass:"checkbox-btn",class:{
                'checkbox-btn--checked': _vm.skipCampById.includes(item.id),
                'checkbox-btn--unchecked': !_vm.skipCampById.includes(item.id)
              },attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.handleSkipCheckboxChange(item.id)}}},[(_vm.skipCampById.includes(item.id))?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-checkbox-marked")]):_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-checkbox-blank-outline")]),_vm._v(" Skip Camp: "+_vm._s(_vm.skipCampById.includes(item.id))+" ")],1):_vm._e(),(item.pointType != 'finish' && !item.legSkip)?_c('v-list-item-subtitle',[_c('legstats',{attrs:{"day":item.journeyDay + (item.pointType != 'start' ? 1 : 0),"compact":true,"iteminfo":item.legStats}})],1):_vm._e(),(item.pointType == 'finish')?_c('v-list-item-subtitle',[_vm._v(" Finish ")]):_vm._e()],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"purple","title":"fly to point","elevation":"2","icon":"","outlined":""},on:{"click":function($event){return _vm.openPopup(item.id)}}},[_c('v-icon',[_vm._v("mdi-map-marker")])],1),(
              !item.legSkip && item.legStats && item.pointType != 'finish'
            )?_c('downloadGpxButton',{attrs:{"name":item.Campsite,"icon":"mdi-download-box","geojson":item.legLineString,"legTitle":item.legStats.legIdDlFile}}):_vm._e()],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }