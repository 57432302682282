const setup = require("./setup");
const filter = require("./filter");

const updateFilteredTack = state => {
  let filterOutput = filter.filterTrack(
    state.initalTrackLineString,
    state.campsitesConfig,
    state.fromCampOrderIndex,
    state.toCampOrderIndex,
    state.skipCampById
  );

  state.filteredStats = filterOutput.filteredStats;
  state.filteredSites = filterOutput.filteredSites;
  state.filteredTrackLineString = filterOutput.filteredTrackLineString;
  state.filteredCampsitesGeoJson = filterOutput.filteredCampsitesGeoJson;

  state.elevationData = filterOutput.elevationData;
};

const rebuildDataSets = state => {
  let initDataOutput = setup.initData(
    JSON.parse(JSON.stringify(state.currentCampsites)),
    JSON.parse(JSON.stringify(state.currentTrack)),
    state.orderForward
  );

  //alwasys run twice, once without skip array to cache next camp site, once with skip array to calc next camp;
  let filterOutput = filter.filterTrack(
    initDataOutput.updatedTrackLineString,
    initDataOutput.campsitesConfig,
    initDataOutput.minCampOrderIndexVal,
    initDataOutput.maxCampOrderIndexVal,
    []
  );

  if (state?.skipCampById?.length) {
    filterOutput = filter.filterTrack(
      initDataOutput.updatedTrackLineString,
      initDataOutput.campsitesConfig,
      initDataOutput.minCampOrderIndexVal,
      initDataOutput.maxCampOrderIndexVal,
      state.skipCampById
    );
  }

  state.initalTrackLineString = initDataOutput.updatedTrackLineString;
  state.campsitesConfig = initDataOutput.campsitesConfig;
  state.initalCampsitesGeoJson = initDataOutput.initalCampsitesGeoJson;
  state.campsitesLookup = initDataOutput.campsitesLookup;
  state.campsitesOrderIndexArray = initDataOutput.campsitesOrderIndexArray;
  state.minCampOrderIndexVal = initDataOutput.minCampOrderIndexVal;
  state.maxCampOrderIndexVal = initDataOutput.maxCampOrderIndexVal;

  state.fromCampOrderIndex = initDataOutput.minCampOrderIndexVal;
  state.toCampOrderIndex = initDataOutput.maxCampOrderIndexVal;

  state.filteredStats = filterOutput.filteredStats;
  state.filteredSites = filterOutput.filteredSites;
  state.filteredTrackLineString = filterOutput.filteredTrackLineString;
  state.filteredCampsitesGeoJson = filterOutput.filteredCampsitesGeoJson;

  state.elevationData = filterOutput.elevationData;

  state.sectionSetLookup = setup.updateSectionSetLookup({
    sectionSet: state.sectionSet,
    campsitesLookup: initDataOutput.campsitesLookup
  });
};

module.exports.updateFilteredTack = updateFilteredTack;
module.exports.rebuildDataSets = rebuildDataSets;
