<template>
  <div class="d-flex flex-row legStats sublegblock">
    <div class="pr-2" v-if="day">
      <font class="prop">Day: </font>
      <font class="day">{{ day }} </font>
    </div>
    <div class="pr-2" v-if="iteminfo.distance">
      <font class="prop" v-if="!compact">Distance: </font>
      <font class="prop" v-if="compact">Dist: </font>
      <font class="distance">{{ iteminfo.distance }} km </font>
    </div>
    <div class="pr-2" v-if="iteminfo.rise">
      <font class="prop" v-if="!compact">Elevation Gain: </font>
      <font class="prop" v-if="compact">Gain: </font>
      <font class="rise">{{ iteminfo.rise }}m </font>
    </div>
    <div class="pr-2" v-if="iteminfo.fall">
      <font class="prop" v-if="!compact">Elevation Descent: </font>
      <font class="prop" v-if="compact">Desc: </font>
      <font class="descent">{{ iteminfo.fall }}m </font>
    </div>
  </div>
</template>

<script>
export default {
  name: "legstat",

  components: {},

  data: () => ({}),
  props: {
    iteminfo: Object,
    compact: Boolean,
    day: Number
  },
  methods: {}
};
</script>

<style>
.legStats,
.sublegblock {
  color: black;
}
.legStats .prop {
  font-weight: bold;
}

.legStats .rise {
  color: red;
}

.legStats .descent {
  color: blue;
}
</style>
