const turf = require("@turf/turf");

var _initial = new Date();

const runTime = () => {
  let seconds = (new Date() - _initial) / 1000;

  return seconds;
};

const logOutTime = false;
const logTime = what => {
  if (logOutTime) {
    console.log({ t: runTime(), w: what });
  }
};

const sortCampSitesByIntersectIndex = campsitesConfig => {
  campsitesConfig.sort((a, b) =>
    a.intersectIndex > b.intersectIndex
      ? 1
      : b.intersectIndex > a.intersectIndex
      ? -1
      : 0
  );
  return campsitesConfig;
};

const sortCampSitesById = campsites => {
  campsites.features.sort((a, b) =>
    a.properties.id > b.properties.id
      ? 1
      : b.properties.id > a.properties.id
      ? -1
      : 0
  );

  return campsites;
};

//this code buffers the camp site and finds where it intersects the
const prepareConfig = (initalTrackLineString, campsites, orderForward) => {
  campsites = sortCampSitesById(campsites);

  logTime("sortIdDone");
  var campsitesConfig = [];

  const getMinDistanceResult = results => {
    results.sort((a, b) =>
      a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0
    );
    let [result] = results;
    return result;
  };

  const calculateClosestLineStringPointToEachCampsitePoint = (
    linestring,
    campsites
  ) => {
    const minCampDistance = 6; //1.5km;
    let campsitesConfig = [];
    campsites.features.forEach((campsite, campIndex) => {
      //new campSite;
      let thisCampSiteDistances = [];
      linestring.geometry.coordinates.forEach((point, lineStringIndex) => {
        let lineStringPoint = turf.point(point);
        let distance = turf.distance(lineStringPoint, campsite);
        if (distance < minCampDistance) {
          thisCampSiteDistances.push({
            distance,
            campIndex,
            lineStringIndex,
            point
          });
        }
      });

      let result = getMinDistanceResult(thisCampSiteDistances);

      let elevation = result?.point[2];

      let extras = {};

      if (!isNaN(elevation)) {
        extras = { elevation };
        campsite.properties.elevation = elevation;
      }

      let campSiteSetup = {
        intersectIndex: result.lineStringIndex,
        intersectPoint: result.point,
        title: campsite.properties.Campsite,
        id: campsite.properties.id,
        point: campsite,
        ...extras
      };
      campsitesConfig.push(campSiteSetup);
    });

    return {
      campsitesConfig
    };
  };

  const results = calculateClosestLineStringPointToEachCampsitePoint(
    initalTrackLineString,
    campsites
  );

  campsitesConfig = results.campsitesConfig;

  //update data here;

  //sort by intersectIndexId;

  campsitesConfig = sortCampSitesByIntersectIndex(campsitesConfig);

  campsitesConfig.forEach((row, orderIndex) => {
    let { point, intersectIndex } = row;
    let { DistNxtCam, DistPrvCam } = point.properties;

    row.orderIndex = orderIndex;
    row.label = `${point.properties.id}-${point.properties.Campsite}`;

    try {
      row.point.properties.orderIndex = orderIndex;
      row.point.properties.intersectIndex = intersectIndex;
    } catch (error) {
      //do nothing;
    }

    try {
      row.point.properties.nextDist = orderForward ? DistNxtCam : DistPrvCam;
    } catch (error) {
      //do nothing;
    }

    try {
      delete row.auxiliary_storage_labeling_positionx;
      delete row.auxiliary_storage_labeling_positiony;
      delete row.Thumb_URL;
      delete row.Photo_URL;
    } catch (error) {
      //do nothing;
    }

    try {
      delete row.point.properties.auxiliary_storage_labeling_positionx;
      delete row.point.properties.auxiliary_storage_labeling_positiony;
      delete row.point.properties.Thumb_URL;
      delete row.point.properties.Photo_URL;
    } catch (error) {
      //do nothing;
    }
  });

  logTime("endPart2");

  let minVal = null,
    maxVal = null;
  campsitesConfig.forEach(row => {
    let { orderIndex } = row;
    if (!isNaN(orderIndex)) {
      if (minVal === null) {
        minVal = orderIndex;
      }
      if (maxVal === null) {
        maxVal = orderIndex;
      }

      if (minVal > orderIndex) {
        minVal = orderIndex;
      }
      if (maxVal < orderIndex) {
        maxVal = orderIndex;
      }
    }
  });

  let initalCampsitesGeoJson = {
    type: "FeatureCollection",
    features: [
      ...campsitesConfig.map(row => {
        return {
          ...row.point
        };
      })
    ]
  };

  let campsitesLookup = {};

  campsitesConfig.forEach(row => {
    campsitesLookup[row.orderIndex] = row;
  });

  let campsitesOrderIndexArray = Object.keys(campsitesLookup);
  logTime("endPart4");

  logTime("endPart5");

  let updatedTrackLineString = JSON.parse(
    JSON.stringify(initalTrackLineString)
  );
  logTime("endPart5");
  /*
  updatedTrackLineString = checkBuffOverlapsPoints(
    updatedTrackLineString,
    campsitesConfig
  );
*/
  logTime("endPart6");

  return {
    updatedTrackLineString,
    campsitesConfig,
    campsitesLookup,
    campsitesOrderIndexArray,
    initalCampsitesGeoJson,
    minCampOrderIndexVal: minVal,
    maxCampOrderIndexVal: maxVal
  };
};
function shuffleArray(array) {
  for (var i = array.length - 1; i >= 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const getMedia = () => {
  let images = ["IMG_6832", "IMG_6879", "IMG_6882", "IMG_6938", "IMG_6944"];

  let streetviews = [
    "https://www.google.com/maps/embed?pb=!4v1728105258550!6m8!1m7!1sF%3A-7DhQsBOxWME%2FViYQnbPVAKI%2FAAAAAAAAP_E%2FyHsV47t6x9IkocsnqVeMh0uHXtH4MnaRQCLIB!2m2!1d-35.17078770208362!2d148.6022044154981!3f89.71!4f12.510000000000005!5f0.7820865974627469",
    "https://www.google.com/maps/embed?pb=!4v1728105335716!6m8!1m7!1sF%3A-14NVSl0fybg%2FV_V9wI7fKjI%2FAAAAAAAAUXk%2F0WJD5-LgpEg4E9M8sHM7L0gFF5fKxLZ-wCLIB!2m2!1d-34.97858993007051!2d148.6270383302956!3f205.33!4f4.510000000000005!5f0.7820865974627469",
    "https://www.google.com/maps/embed?pb=!4v1728105382376!6m8!1m7!1sF%3A-hjoyWf4YUt0%2FWOS_JFlOhrI%2FAAAAAAAAVEo%2Fj-bavW33ycQAqGElg4gC-fHWHAc0RwpLQCLIB!2m2!1d-35.40194621381242!2d148.2395829773141!3f256.24!4f7.420000000000002!5f1.4875362797940799",
    "https://www.google.com/maps/embed?pb=!4v1728105426603!6m8!1m7!1sF%3A-j7j7J-5p45s%2FV-h2CA-F9YI%2FAAAAAAAALeI%2FJ9qg36r-NhwhUwLCJs_SHPTciEn5M-TygCLIB!2m2!1d-35.9973204220905!2d147.1479923633158!3f143.69324324481505!4f-5.096864562186852!5f0.7820865974627469"
  ];

  streetviews = streetviews.map(row => {
    return {
      label: "streetviews",
      value: {
        title: "",
        url: row,
        target: ""
      }
    };
  });
  let links = [
    {
      label: "Site Link 01",
      value: {
        title: "Link Example 1",
        url: "https://www.google.com",
        target: ""
      }
    },
    {
      label: "Site Link 01",
      value: {
        title: "Link Example 2",
        url: "https://www.google.com",
        target: ""
      }
    },
    {
      label: "Site Link 01",
      value: {
        title: "Link Example 3",
        url: "https://www.google.com",
        target: ""
      }
    },
    {
      label: "Site Link 01",
      value: {
        title: "Link Example 4",
        url: "https://www.google.com",
        target: ""
      }
    },
    {
      label: "Site Link 01",
      value: {
        title: "",
        url: "https://www.google.com",
        target: ""
      }
    }
  ];

  let youtube = [
    {
      label: "Video Link 1##S_POP",
      value: {
        title: "Portable Espresso",
        url: "https://www.youtube.com/watch?v=ig_vJqAjyfU",
        target: ""
      }
    },
    {
      label: "Video Link 2##S_POP",
      value: {
        title: "Tiny 2-Stroke Engine",
        url: "https://www.youtube.com/watch?v=nKVq9u52A-c",
        target: ""
      }
    },

    {
      label: "Video Link 3##S_POP",
      value: {
        title: "How The World's Finest Saws Are Made",
        url: "https://www.youtube.com/watch?v=A6BAXmR2REg",
        target: ""
      }
    },

    {
      label: "Video Link 4##S_POP",
      value: {
        title: "How The World's Finest Saws Are Made",
        url: "https://www.youtube.com/watch?v=NdkEGdMOobo",
        target: ""
      }
    }
  ];

  shuffleArray(images);
  shuffleArray(streetviews);
  shuffleArray(youtube);
  shuffleArray(links);

  let num1 = 2;
  let num2 = 3;
  youtube.length = Math.floor(Math.random() * (num2 - num1 + 1) + num1);
  links.length = Math.floor(Math.random() * (num2 - num1 + 1) + num1);

  num1 = 1;
  num2 = 2;
  streetviews.length = Math.floor(Math.random() * (num2 - num1 + 1) + num1);

  num1 = 1;
  num2 = 5;
  images.length = Math.floor(Math.random() * (num2 - num1 + 1) + num1);

  let media = {};

  media.gallery = images.map(row => {
    return {
      id: 819 + Math.floor((Math.random() + 1) * 1000),
      title: row,
      caption: row,
      description: row,
      src: `/sampleImages/${row}.jpg`,
      src_thumb: `/sampleImages/${row}.jpg`,
      src_gallery: `/sampleImages/${row}.jpg`,
      src_gallery_sm: `/sampleImages/${row}.jpg`,
      post_mime_type: "image/jpeg"
    };
  });

  //media.gallery.length = 1;

  media.videos = youtube;
  media.links = links;
  media.streetviews = streetviews;

  return media;
};

const cleanCampSiteItem = campsite => {
  //console.log(campsite.properties);

  campsite.properties.id = String(campsite.properties.id);
  campsite.properties.title = String(campsite.properties.Campsite);

  try {
    campsite.properties.walkDescription = `Deails about the trail conditions of ${campsite.properties.title}......`;
  } catch (error) {
    //do nothing;
  }

  delete campsite.properties.auxiliary_storage_labeling_positionx;
  delete campsite.properties.auxiliary_storage_labeling_positiony;
  delete campsite.properties.Thumb_URL;
  delete campsite.properties.Photo_URL;
};

const cleanData = (campsites, track) => {
  if (track) {
    //do nothing;
  }

  campsites.features.forEach(campsite => {
    cleanCampSiteItem(campsite);

    campsite.properties.media = getMedia();
  });

  // return { campsites, track };
};

const _updateSectionSetLookup = ({ campsitesLookup, sectionSet }) => {
  let sectionSetLookup = {};
  if (sectionSet && sectionSet.length) {
    sectionSet.forEach((section, index) => {
      try {
        let { startId, endId } = section;
        let values = Object.values(campsitesLookup)
          .filter(row => row.id === startId || row.id === endId)
          .map(row => row.orderIndex);

        let [startIndex, endIndex] = values;

        //console.log({values, startIndex, endIndex});
        sectionSetLookup[index] = { ...section, index, startIndex, endIndex };
        //console.log(sectionSetLookup[index]);
      } catch (error) {
        console.error({ error });
      }
    });
  }

  return sectionSetLookup;
};

const _initData = (campsites, track, orderForward) => {
  cleanData(campsites, track);

  _initial = new Date();

  let initalTrackLineString = track;
  if (
    track.type == "FeatureCollection" &&
    track.features &&
    track.features[0] &&
    track.features[0]?.geometry?.type === "LineString"
  ) {
    initalTrackLineString = track.features[0];
  }

  if (orderForward !== true) {
    initalTrackLineString.geometry.coordinates.reverse();
  }

  logTime("start");

  console.log({ campsites });

  var setup = prepareConfig(initalTrackLineString, campsites, orderForward);

  logTime("end");
  return { ...setup, initalTrackLineString };
};

module.exports.initData = _initData;
module.exports.updateSectionSetLookup = _updateSectionSetLookup;
