<template>
  <div>
    <tripCampSiteItem :campsite="legItem.fromSite"></tripCampSiteItem>

    <v-card class="mb-2 report-row" v-if="legItem && legItem.journeyDay">
      <v-card-title>
        <v-icon>mdi-walk</v-icon> Section: {{ legItem.fromToTitle }} Day:
        {{ legItem.journeyDay }}
      </v-card-title>
      <v-card-text>
        <downloadGpxButton
          v-if="legItem.fromSite.legStats.legTitle"
          :name="legItem.fromSite.Campsite"
          icon="mdi-download-box"
          title="Download Section GPX"
          :report="true"
          :geojson="legItem.fromSite.legLineString"
          :legTitle="legItem.fromSite.legStats.legIdDlFile"
        ></downloadGpxButton>
      </v-card-text>

      <v-card-text v-if="legItem && legItem.legStats" class="mb-0 py-0">
        <legstats :iteminfo="legItem.legStats"></legstats>
      </v-card-text>

      <v-card-text v-if="legItem.subLegs.length" class="mb-0 py-0">
        <sublegstats :iteminfo="legItem.subLegs"></sublegstats>
      </v-card-text>

      <v-card-text v-if="legItem.subLegs.length" class="mb-0 py-0">
        <SubLegCampsites :iteminfo="legItem.inbetweenStops"></SubLegCampsites>
      </v-card-text>
    </v-card>

    <tripCampSiteItem
      v-if="legItem && legItem.lastItem"
      :campsite="legItem.toSite"
    >
    </tripCampSiteItem>
  </div>
</template>

<script>
import tripCampSiteItem from "./tripCampSiteItem.vue";
import legstats from "./../infoblocks/legstats.vue";
import sublegstats from "./../infoblocks/sublegstats.vue";
import SubLegCampsites from "./../infoblocks/SubLegCampsites.vue";

import downloadGpxButton from "./../ui/downloadGpxButton.vue";

export default {
  name: "tripReportItem",
  components: {
    tripCampSiteItem,
    legstats,
    sublegstats,
    SubLegCampsites,
    downloadGpxButton
  },

  props: {
    legItem: Object
  },

  computed: {},
  mounted() {
    //console.log(this.legItem);
  }
};
</script>
