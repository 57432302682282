<template>
  <div class="mediaButtonsWrapper">
    <div class="pa-1 pb-0">
      <mediaButton
        v-if="media.gallery && media.gallery.length"
        :width="width"
        :type="{
          prop: 'gallery',
          title: 'gallery',
          icon: 'mdi-image-multiple',
          single: 'Gallery'
        }"
        :gallery="media.gallery"
        :title="'Gallery'"
        :markertitle="properties.title"
      ></mediaButton>
    </div>

    <div v-for="type in types" :key="`section_${type.prop}`">
      <div
        v-for="(item, i) in media[type.prop]"
        :key="`${type.prop}_${i}`"
        class="pa-1 pb-0"
      >
        <mediaButton
          v-if="item.value && item.value.url && type && type.prop"
          :width="width"
          :type="type"
          :url="item.value.url"
          :title="item.value.title"
          :markertitle="properties.title"
        ></mediaButton>
      </div>
    </div>
  </div>
</template>

<script>
import mediaButton from "./mediaButton";

export default {
  name: "mediaButtons",

  components: { mediaButton },

  data: () => ({
    types: [
      { prop: "videos", title: "videos", icon: "mdi-youtube", single: "Video" },
      { prop: "links", title: "links", icon: "mdi-link", single: "Link" },
      {
        prop: "streetviews",
        title: "streetviews",
        icon: "mdi-web",
        single: "Photosphere"
      }
    ]
  }),
  props: {
    width: Object,
    properties: Object,
    media: Object
  },
  methods: {},
  computed: {}
};
</script>

<style>
div.mediaButtonsWrapper {
  width: 300px;
}
</style>
