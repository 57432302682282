<template>
  <div max-width="450" class="mx-auto">
    <v-toolbar color="cyan" dark dense>
      <v-toolbar-title>List</v-toolbar-title>
    </v-toolbar>

    <div class="campsiteListWrapper">
      <journeyStats
        v-if="filteredStats && filteredStats.fromTitle"
        class="pa-2"
        :iteminfo="filteredStats"
      ></journeyStats>

      <br />
      <div>
        <tripReport></tripReport>
      </div>

      <br />
      <br />
      <div>
        <elevationGraph
          v-if="elevationData && elevationData.campsiteDistElevationArray"
        ></elevationGraph>
      </div>

      <v-list three-line>
        <template v-for="(item, index) in filteredCampsitesArray">
          <v-list-item :key="item.id + ' ' + index">
            <v-list-item-avatar
              style="margin:16px 8px 8px -8px"
              size="24"
              :color="appSettings.markerColors[item.pointType]"
            >
              <font v-if="false">{{ item.journeyDay }}</font>
              {{ item.id }}
            </v-list-item-avatar>

            <v-list-item-content
              ><a @click="openPopup(item.id)">
                <v-list-item-title
                  v-html="
                    `${item.Campsite} - (${item.id}) ${
                      item.pointType != 'finish' &&
                      item.pointType != 'start' &&
                      item.legSkip
                        ? '-skipped'
                        : ''
                    }`
                  "
                ></v-list-item-title>
              </a>

              <v-btn
                v-if="item.pointType != 'finish' && item.pointType != 'start'"
                :class="{
                  'checkbox-btn--checked': skipCampById.includes(item.id),
                  'checkbox-btn--unchecked': !skipCampById.includes(item.id)
                }"
                @click="handleSkipCheckboxChange(item.id)"
                class="checkbox-btn"
                outlined
                small
              >
                <v-icon left v-if="skipCampById.includes(item.id)"
                  >mdi-checkbox-marked</v-icon
                >
                <v-icon left v-else>mdi-checkbox-blank-outline</v-icon>
                Skip Camp:
                {{ skipCampById.includes(item.id) }}
              </v-btn>
              <v-list-item-subtitle
                v-if="item.pointType != 'finish' && !item.legSkip"
              >
                <legstats
                  :day="item.journeyDay + (item.pointType != 'start' ? 1 : 0)"
                  :compact="true"
                  :iteminfo="item.legStats"
                ></legstats>
              </v-list-item-subtitle>

              <v-list-item-subtitle v-if="item.pointType == 'finish'">
                Finish
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-btn
              class="mr-1"
              @click="openPopup(item.id)"
              color="purple"
              title="fly to point"
              elevation="2"
              icon
              outlined
            >
              <v-icon>mdi-map-marker</v-icon></v-btn
            >
            <downloadGpxButton
              v-if="
                !item.legSkip && item.legStats && item.pointType != 'finish'
              "
              :name="item.Campsite"
              icon="mdi-download-box"
              :geojson="item.legLineString"
              :legTitle="item.legStats.legIdDlFile"
            ></downloadGpxButton>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import { mapState } from "vuex";
import { eventBus } from "./../../main";

import downloadGpxButton from "./../ui/downloadGpxButton.vue";
import elevationGraph from "./../elevation/elevationGraph.vue";

import tripReport from "./../report/tripReport.vue";
import journeyStats from "./../infoblocks/journeyStats";
import legstats from "./../infoblocks/legstats.vue";

export default {
  name: "CampSiteSideList",
  components: {
    downloadGpxButton,
    elevationGraph,
    tripReport,
    journeyStats,
    legstats
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      elevationData: state => state.trail.elevationData,
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson,
      filteredTrackLineString: state => state.trail.filteredTrackLineString,
      filteredStats: state => state.trail.filteredStats
    }),

    skipCampById: {
      get() {
        return this.$store.state.trail.skipCampById;
      },
      set(value) {
        this.$store.dispatch("trail_update_skiplist_set", value);
      }
    },

    filteredCampsitesArray() {
      let array = this.filteredCampsitesGeoJson.features.map(row => {
        return row.properties;
      });

      return array;
    }
  },
  created() {},
  methods: {
    handleSkipCheckboxChange(id) {
      let isChecked = !this.skipCampById.includes(id);

      if (isChecked) {
        // Add id to the array if checked
        this.skipCampById.push(id);
      } else {
        // Remove id from the array if unchecked
        const index = this.skipCampById.indexOf(id);
        if (index > -1) {
          this.skipCampById.splice(index, 1);
        }
      }
      this.$store.dispatch("trail_update_skiplist_set", this.skipCampById);

      eventBus.$emit("map_clearAllPopups");
    },

    openPopup(id) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        eventBus.$emit("nav_layout_closeMenu", false);
      }

      eventBus.$emit("map_openCampSitePopupById", id);
    }
  }
};
</script>

<style>
#topOfHelpSection img {
  max-width: 80%;
}

.campsiteListWrapper {
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}

.checkbox-btn {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 8px;
  min-width: 80px; /* Optional: Adjust to suit your design */

  width: 168px;
  flex: none;
}

.checkbox-btn--checked {
  background-color: #ff6262; /* Checked background color (Green) */
  color: white; /* Checked text color */
}

.checkbox-btn--unchecked {
  background-color: white; /* Unchecked background color */
  color: black; /* Unchecked text color */
}

.checkbox-btn .v-icon {
  margin-right: 8px;
}
</style>
