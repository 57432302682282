<template>
  <div class="d-flex flex-row subLegStats sublegblock">
    <div class="pr-2 subheading">
      <b>SubLegs:</b>
    </div>
    <div class="pr-2">
      <div v-for="item in iteminfo" :key="item.title">
        {{ item.fromTitle }} -
        {{ item.toTitle }}

        <font v-if="item.distance" class="pl-2 prop">Length: </font>
        <font v-if="item.distance" class="distance"
          >{{ item.distance }} km
        </font>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "subLegstat",

  components: {},

  data: () => ({}),
  props: {
    iteminfo: Object
  },
  methods: {}
};
</script>

<style></style>
