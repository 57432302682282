const orchestration = require("./orchestration");
const validate = require("./validate");

let orderForward = true;

const getters = {};

const checkForActiveSection = (state, commit) => {
  let { fromCampOrderIndex, toCampOrderIndex, sectionSetLookup } = state;

  let values = Object.values(sectionSetLookup).filter(
    row =>
      row.startIndex == fromCampOrderIndex && row.endIndex == toCampOrderIndex
  );

  console.log(values);
  if (values.length) {
    console.log("found", values[0].index);
    commit("UPDATE_CURRENT_SECTION_INDEX", values[0].index);
  } else {
    commit("UPDATE_CURRENT_SECTION_INDEX", null);
  }
};

const mutations = {
  RESET_START_END(state) {
    state.fromCampOrderIndex = state.minCampOrderIndexVal;
    state.toCampOrderIndex = state.maxCampOrderIndexVal;

    orchestration.updateFilteredTack(state);
  },
  UPDATE_CURRENT_SECTION_INDEX(state, newValue) {
    if (
      (state.sectionSetLookup && state.sectionSetLookup[newValue]) ||
      newValue == null
    ) {
      state.currentSectionIndex = newValue;
    }
  },
  UPDATE_FROM(state, newValue) {
    state.fromCampOrderIndex = newValue;

    validate.validateToFrom(state, "from", 0);

    orchestration.updateFilteredTack(state);
  },
  UPDATE_TO(state, newValue) {
    state.toCampOrderIndex = newValue;

    validate.validateToFrom(state, "to", 0);

    orchestration.updateFilteredTack(state);
  },
  UPDATE_ORDER(state, newValue) {
    state.orderForward = newValue;
    orchestration.rebuildDataSets(state);
  },
  UPDATE_CURRENT_SET(state, newValue) {
    state.currentSet = newValue;
    orchestration.rebuildDataSets(state);
  },
  UPDATE_EVERYTHING(state) {
    state.initalTrackLineString = JSON.parse(
      JSON.stringify(state.initalTrackLineString)
    );
    state.initalCampsitesGeoJson = JSON.parse(
      JSON.stringify(state.initalCampsitesGeoJson)
    );
    state.filteredCampsitesGeoJson = JSON.parse(
      JSON.stringify(state.filteredCampsitesGeoJson)
    );
    state.filteredTrackLineString = JSON.parse(
      JSON.stringify(state.filteredTrackLineString)
    );
  },

  SET_DATASETS(state, data) {
    if (data && data.mapboxTrailDataObject) {
      let { mapboxTrailDataObject } = data;

      console.log(mapboxTrailDataObject);

      state.mapboxTrailDataObject = mapboxTrailDataObject;

      state.allSets = Object.keys(mapboxTrailDataObject);
    }

    if (state.mapboxTrailDataObject) {
      let set = null;

      let [firstKey] = Object.keys(state.mapboxTrailDataObject);

      if (state.currentSet && state.mapboxTrailDataObject[state.currentSet]) {
        set = state.mapboxTrailDataObject[state.currentSet];
      } else if (state.mapboxTrailDataObject[firstKey]) {
        set = state.mapboxTrailDataObject[firstKey];
      }
      state.currentSet = set.name;
      state.currentCampsites = set?.campsitesData?.data;
      state.currentTrack = set?.trackData?.data;

      state.sectionSet = [];

      try {
        state.sectionSet = JSON.parse(JSON.stringify(set?.sectionSet));
      } catch (error) {
        console.error("section set failed to load");
      }

      orchestration.rebuildDataSets(state);
    }
  },

  RESET_ORDER_SET(state) {
    state.orderForward = true;
    state.currentSet = "normal";
  },

  UPDATE_SKIPLIST_SET(state, newValue) {
    state.skipCampById = newValue;

    orchestration.updateFilteredTack(state);

    console.log("UPDATE_SKIPLIST_SET, ");
  }
};

const actions = {
  trail_update_everything({ commit }) {
    commit("UPDATE_EVERYTHING");
  },
  trail_update_current_section_index({ commit, state }, newValue) {
    commit("UPDATE_CURRENT_SECTION_INDEX", newValue);

    if (state.sectionSetLookup && state.sectionSetLookup[newValue]) {
      let { startIndex, endIndex } = state.sectionSetLookup[newValue];
      //should be the first and second camp site index;
      try {
        if (
          state.campsitesLookup[startIndex] &&
          state.campsitesLookup[endIndex]
        ) {
          commit("UPDATE_TO", endIndex);
          commit("UPDATE_FROM", startIndex);
          commit("UPDATE_TO", endIndex);
          commit("UPDATE_FROM", startIndex);
        }
      } catch (error) {
        console.error({ error });
      }
    }
  },
  trail_update_from({ commit }, value) {
    commit("UPDATE_CURRENT_SECTION_INDEX", null);
    commit("UPDATE_FROM", value);

    checkForActiveSection(state, commit);
  },
  trail_update_to({ commit }, value) {
    commit("UPDATE_CURRENT_SECTION_INDEX", null);
    commit("UPDATE_TO", value);

    checkForActiveSection(state, commit);
  },
  trail_setOrder({ commit }, value) {
    commit("UPDATE_ORDER", value);
  },
  trail_reset_options({ commit, state }) {
    commit("UPDATE_CURRENT_SECTION_INDEX", null);
    commit("UPDATE_SKIPLIST_SET", []);
    if (state.orderForward === true && state.currentSet == "normal") {
      commit("RESET_START_END", true);
    } else {
      commit("RESET_ORDER_SET", true);
      commit("SET_DATASETS", null);
    }

    checkForActiveSection(state, commit);
  },
  trail_init_loaded_data({ commit }, data) {
    let { mapboxTrailDataObject } = data;
    if (mapboxTrailDataObject) {
      //DO NOTHING;
    }

    commit("SET_DATASETS", data);

    checkForActiveSection(state, commit);
  },
  trail_update_current_set({ commit }, data) {
    commit("UPDATE_CURRENT_SET", data);
    commit("SET_DATASETS", null);
  },
  trail_update_skiplist_set({ commit }, data) {
    commit("UPDATE_SKIPLIST_SET", data);
  }
};
const state = {
  currentSet: "normal",
  allSets: [],
  skipCampById: [], //["17", "22", "11", "8"];
  sectionSet: [],
  sectionSetLookup: {},
  currentSectionIndex: null,

  mapboxTrailDataObject: {}, //All different trail versions input data;
  currentCampsites: {},
  currentTrack: {},

  orderForward,

  initalTrackLineString: {
    type: "FeatureCollection",
    features: []
  },
  initalCampsitesGeoJson: {
    type: "FeatureCollection",
    features: []
  },

  minCampOrderIndexVal: -1,
  maxCampOrderIndexVal: -1,
  fromCampOrderIndex: -1,
  toCampOrderIndex: -1,
  campsitesConfig: null,
  campsitesLookup: null,
  campsitesOrderIndexArray: null,

  filteredSites: {},

  elevationData: {},

  filteredCampsitesGeoJson: {
    type: "FeatureCollection",
    features: []
  },
  filteredTrackLineString: {
    type: "FeatureCollection",
    features: []
  },

  filteredStats: null
};

export default {
  state,
  getters,
  actions,
  mutations
};
